import { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';
import { MdError, MdHelp } from 'react-icons/md';
import { jwtDecode } from 'jwt-decode';
import Tooltip from 'components/Tooltip/Tooltip';
import getHighlightParts from 'utils/getHighlightParts';
import getHighlightFlag from 'utils/getHighlightFlag';

import PostContent from './PostContent';
import PostEditorContainer from 'components/PostEditor/PostEditorContainer';
import UserContext from 'contexts/UserContext';
import MoreActions from 'components/MoreActions/MoreActions';
import Replies from './Reply/Replies';
import Byline from './PostByline';
import Timestamp from 'components/Timestamp';
import { ReactComponent as PinIcon } from 'img/pin-icon.svg';
import s from './Post.module.scss';
import { GUEST, ADMIN, EDITOR, VISITOR } from 'utils/roles';
import useIsDevUIEnabled from 'hooks/useIsDevUIEnabled';

const Post = ({
  post,
  streamId,
  removePost,
  updatePost,
  renewPost,
  togglePin,
  toggleHighlight,
  createReply,
  unsetIsEditing,
  setIsEditing,
  setIsReplying,
  unsetIsReplying,
  copyId,
  copyVideoSvtId,
  openInGoogleConsole,
  isEditing,
  isReplying,
  isEditable,
  isHighlightsFeatureEnabled = false,
}) => {
  const verifySignatureEnabled = Boolean(
    Number(process.env.REACT_APP_VERIFY_SIGNATURE_ENABLED)
  );

  const mCreatedAt = post.createdAt && moment(post.createdAt);
  const isVisitor = _.get(post, 'author.role') === VISITOR;

  const isDiscussion =
    !post.pinned && post.replies && Object.keys(post.replies).length > 0;

  const PotentialUpdatePostEditor = isEditing && (
    <div className={s.updatePostEditorWrapper}>
      <PostEditorContainer
        post={post}
        onSubmit={updatePost}
        onCancel={unsetIsEditing}
        submitText="Uppdatera"
        cancelText="Avbryt"
        title="Redigera inlägg"
        attachmentVisible={!isVisitor}
        toolbarVisible={!isVisitor}
      />
    </div>
  );

  const PotentialActions = () => {
    const { isAdminOrEditor, user } = useContext(UserContext);
    const isDevUIEnabled = useIsDevUIEnabled();

    if (!(!isEditing && !isReplying && isEditable)) {
      return null;
    }

    const isHighlightDisabled = isDiscussion || isVisitor || post.pinned;

    let flag;

    if (isHighlightsFeatureEnabled && !isHighlightDisabled) {
      const { body, heading } = getHighlightParts(post);
      flag = getHighlightFlag(body, heading, post);
    }

    const highlightAction = {
      title: post.highlighted ? (
        'Avmarkera händelse'
      ) : (
        <>
          Markera händelse
          {flag && (
            <span className={s.warning}>
              <MdError />
              <Tooltip className={s.tooltip}>{flag}</Tooltip>
            </span>
          )}
        </>
      ),
      callback: isHighlightDisabled ? () => {} : toggleHighlight,
      disabled: isHighlightDisabled,
    };

    const pinAction = {
      title: post.pinned ? 'Avpinna' : 'Pinna',
      callback: post.highlighted ? () => {} : togglePin,
      disabled: Boolean(post.highlighted),
    };

    const renewAction = {
      title: (
        <>
          Ompublicera
          <span className={s.help}>
            <MdHelp />
            <Tooltip className={s.tooltip}>
              Inlägget uppdateras med ny publiceringstid
            </Tooltip>
          </span>
        </>
      ),
      callback: renewPost,
    };

    const editAction = {
      title: 'Redigera',
      callback: setIsEditing,
    };

    const replyAction = {
      title: 'Svara',
      callback: post.highlighted ? () => {} : setIsReplying,
      disabled: Boolean(post.highlighted),
    };

    const removeAction = {
      title: 'Ta bort',
      callback: removePost,
      danger: true,
    };

    const copyPostIdAction = {
      title: 'Kopiera inläggets id',
      callback: copyId,
    };

    const copyVideoSvtIdAction = {
      title: 'Kopiera videons svtId',
      callback: copyVideoSvtId,
    };

    const openPostInGoogleConsole = {
      title: 'Öppna inlägget i Firestore',
      callback: openInGoogleConsole,
    };

    const isGuestPost = post.author.role === GUEST;

    let wasCreatedByCurrentUser = true;

    // TODO: clean up this once toggle REACT_APP_VERIFY_SIGNATURE_ENABLED has been removed
    if (verifySignatureEnabled) {
      const postSignature = post.signature ? jwtDecode(post.signature) : null;
      wasCreatedByCurrentUser = postSignature
        ? postSignature.authorId === user.id
        : false;
    }

    const guestActions =
      isGuestPost && wasCreatedByCurrentUser
        ? [editAction, replyAction]
        : [replyAction];

    const potentialHighlightAction = isHighlightsFeatureEnabled
      ? [highlightAction]
      : [];

    const potentialRenewAction = post.pinned ? [renewAction] : [];

    const adminOrEditorActions = [
      ...potentialHighlightAction,
      pinAction,
      editAction,
      replyAction,
      ...potentialRenewAction,
      removeAction,
    ];

    const developerActions = [
      copyPostIdAction,
      ...(post.attachment?.svtId ? [copyVideoSvtIdAction] : []),
      openPostInGoogleConsole,
    ];

    return (
      <div className={s.actionsWrapper}>
        <MoreActions
          id={post.id}
          buttonLabel="Hantera inlägg"
          actions={[
            ...(isAdminOrEditor ? adminOrEditorActions : guestActions),
            ...(isDevUIEnabled ? developerActions : []),
          ]}
        />
      </div>
    );
  };

  const PotentialCreateReplyEditor = isReplying && (
    <div className={s.createReplyEditorWrapper}>
      <PostEditorContainer
        onSubmit={createReply}
        onCancel={unsetIsReplying}
        submitText="Publicera"
        cancelText="Avbryt"
        attachmentEnabled={false}
        isAuthorEditable={true}
      />
    </div>
  );

  return (
    <article
      className={cn(s.root, {
        [s.rootPinned]: post.pinned && !isEditing,
        [s.rootHighlighted]: post.highlighted && !isEditing,
        [s.isVisitor]: isVisitor,
        [s.isDiscussion]: isDiscussion,
        [s.isEditing]: isEditing,
      })}
      data-testid="post"
    >
      {!isEditing && post.pinned && (
        <div className={s.pin}>
          <div className={s.pinCircle}>
            <PinIcon className={s.pinIcon} />
          </div>
        </div>
      )}
      {!isEditing && !post.pinned && (
        <div className={s.timestamp}>
          <span className={s.timestampCircle}></span>
          <Timestamp mDate={moment(mCreatedAt)} variant="svt" />
        </div>
      )}

      <PotentialActions />
      <div className={s.content}>
        {PotentialUpdatePostEditor}
        {!isEditing && (
          <div className={s.post}>
            <div className={s.postContent}>
              <PostContent post={post} />
            </div>
            <Byline author={post.author} />
          </div>
        )}
      </div>
      <Replies
        replies={post.replies}
        streamId={streamId}
        postId={post.id}
        isEditable={isEditable}
      />
      {PotentialCreateReplyEditor}
    </article>
  );
};

Post.propTypes = {
  post: PropTypes.shape({
    createdAt: PropTypes.string,
    highlighted: PropTypes.bool,
    pinned: PropTypes.bool,
    replies: PropTypes.object,
    id: PropTypes.string.isRequired,
    signature: PropTypes.string,
    attachment: PropTypes.any,
    author: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      role: PropTypes.oneOf([VISITOR, GUEST, EDITOR, ADMIN]),
      title: PropTypes.string,
      avatar: PropTypes.shape({
        path: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  removePost: PropTypes.func,
  updatePost: PropTypes.func,
  renewPost: PropTypes.func,
  togglePin: PropTypes.func,
  toggleHighlight: PropTypes.func,
  createReply: PropTypes.func,
  unsetIsEditing: PropTypes.func,
  setIsEditing: PropTypes.func,
  setIsReplying: PropTypes.func,
  unsetIsReplying: PropTypes.func,
  copyId: PropTypes.func,
  copyVideoSvtId: PropTypes.func,
  openInGoogleConsole: PropTypes.func,
  isEditing: PropTypes.bool,
  isReplying: PropTypes.bool,
  streamId: PropTypes.string,
  isEditable: PropTypes.bool,
  isHighlightsFeatureEnabled: PropTypes.bool,
};

export default Post;
