import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input/Input';
import Attachment from 'components/Attachment/Attachment';
import Loader from 'components/Loader/Loader';
import { ReactComponent as SvtInfo } from 'img/svtinfo.svg';

import s from './LegacyVideoEditor.module.scss';

const LegacyVideoEditor = ({
  inputVideoId,
  handleVideoIdChange,
  inputError,
  attachment,
  loadingVideoData,
}) => (
  <React.Fragment>
    <Input
      autoFocus
      label="SVT-ID eller Escenic-ID för ett videoklipp"
      type="text"
      placeholder="T.ex: eWmAG7J eller 34222494"
      value={inputVideoId}
      onChange={handleVideoIdChange}
      error={inputError}
    />
    {attachment && (
      <div className={s.videoInfoPanel}>
        <div className={s.videoInfoHeadingWrapper}>
          <SvtInfo className={s.infoIcon} />
          <h3 className={s.videoInfoHeading}>Videoinfo</h3>
        </div>

        <div className={s.videoInfo}>
          <div className={s.id}>
            <span className={s.idLabel}>SVT-ID:</span> {attachment.svtId}
          </div>
          {attachment.videoId && (
            <div className={s.id}>
              <span className={s.idLabel}>Escenic-ID:</span>{' '}
              {attachment.videoId}
            </div>
          )}
        </div>
      </div>
    )}
    {attachment && !loadingVideoData && <Attachment attachment={attachment} />}
    {loadingVideoData && <Loader>Laddar video...</Loader>}
    {!attachment && !loadingVideoData && (
      <p className={s.videoAppInfo}>
        Vill du spela in videoklipp med din mobil? Använd appen{' '}
        <a
          href="https://svtwiki.svt.se/pages/viewpage.action?pageId=104300692"
          target="_blank"
          rel="noopener noreferrer"
          title="Läs om appen IN2IT access på SVT Wiki"
        >
          IN2IT access
        </a>
        .
      </p>
    )}
  </React.Fragment>
);

LegacyVideoEditor.propTypes = {
  inputVideoId: PropTypes.string.isRequired,
  handleVideoIdChange: PropTypes.func.isRequired,
  inputError: PropTypes.string,
  attachment: PropTypes.object,
  loadingVideoData: PropTypes.bool,
};

export default LegacyVideoEditor;
