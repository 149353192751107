import { useContext, useState, useCallback } from 'react';

import betterTrim from 'utils/betterTrim';
import { isLikelyDksCode, isSvtId } from 'utils/isValidVideoId';
import AttachmentVideo from 'components/Attachment/AttachmentVideo';
import api from '../../../../services/api';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import {
  PostEditorContext,
  postEditorActions,
} from 'components/PostEditor/PostEditorStore';
import VideoEditor from './VideoEditor';
import { trackEvent } from 'utils/statistics';
import { DirektcenterVideoOrigin } from 'Types';

const VideoEditorContainer = () => {
  const { state: postEditorState, dispatcher: postEditorDispatcher } =
    useContext(PostEditorContext);
  const attachment = postEditorState.postInProgress.attachment;

  const [inputSvtId, setInputSvtId] = useState(attachment.svtId);
  const [loadingVideoData, setLoadingVideoData] = useState(false);
  const [inputError, setInputError] = useState<string>();

  const resetAttachment = useCallback(() => {
    postEditorDispatcher({
      type: postEditorActions.SET_ATTACHMENT,
      payload: VideoEditorContainer.initialData,
    });
  }, [postEditorDispatcher]);

  const fetchVideoData = useCallback(async (svtId: string) => {
    const res = await api.fetchVideoData(svtId);

    if (res.status !== 'success') {
      if (res.status === 'error') {
        throw new Error(res.message);
      } else {
        throw new Error('Något gick fel vid hämtning av videodata.');
      }
    }

    return {
      aspectRatio: res.data.aspectRatio,
      posterImageId: res.data.posterImageId,
      duration: res.data.duration,
      svtId: res.data.svtId,
      astridImage: res.data.astridImage,
    };
  }, []);

  const reactToUserInputChange = useCallback(
    async (svtIdInput: string, origin: DirektcenterVideoOrigin) => {
      setInputError(undefined);
      try {
        const { aspectRatio, posterImageId, duration, svtId, astridImage } =
          await fetchVideoData(svtIdInput);

        postEditorDispatcher({
          type: postEditorActions.MERGE_ATTACHMENT,
          payload: {
            type: AttachmentVideo.type,
            aspectRatio,
            posterImageId,
            astridImage,
            duration,
            svtId,
            origin,
          },
        });
      } catch (err) {
        resetAttachment();
        setInputError(
          'Kunde inte hitta videon, kontrollera ID:t och att videon är publicerad.'
        );
      }

      setLoadingVideoData(false);
    },
    [fetchVideoData, postEditorDispatcher, resetAttachment]
  );

  const debouncedReactToUserInputChange = useDebouncedCallback(
    reactToUserInputChange,
    1000
  );

  const handleSvtIdChange = (
    svtId: string,
    origin: DirektcenterVideoOrigin
  ) => {
    // Todo: Possibly use separate callback for uploaded video instead of this
    if (origin === 'other') {
      resetAttachment();
    }

    setInputError(undefined);
    setInputSvtId(svtId);

    const trimmedSvtIdSvtId = betterTrim(svtId) as string;

    if (trimmedSvtIdSvtId && isLikelyDksCode(trimmedSvtIdSvtId)) {
      setInputError(
        'Det verkar som om du har fyllt i ett filnamn (DKS-kod), ange ett giltigt SVT-ID i stället.'
      );
      trackEvent({
        name: 'video-id-validation-fail',
        eventValues: {
          validationType: 'likelyDksCode',
          validationValue: trimmedSvtIdSvtId,
        },
      });
      return;
    }

    if (trimmedSvtIdSvtId && !isSvtId(trimmedSvtIdSvtId)) {
      setInputError('Ange ett giltigt SVT-ID.');
      trackEvent({
        name: 'video-id-validation-fail',
        eventValues: {
          validationType: 'invalidVideoId',
          validationValue: trimmedSvtIdSvtId,
        },
      });
      return;
    }

    if (trimmedSvtIdSvtId) {
      setLoadingVideoData(true);
      debouncedReactToUserInputChange(trimmedSvtIdSvtId, origin);
    }
  };

  return (
    <VideoEditor
      inputSvtId={inputSvtId}
      handleSvtIdChange={handleSvtIdChange}
      inputError={inputError}
      attachment={AttachmentVideo.validate(attachment) ? attachment : null}
      loadingVideoData={loadingVideoData}
      setLoadingVideoData={setLoadingVideoData}
      caption={attachment.caption}
      setInputCaption={(caption: string) => {
        postEditorDispatcher({
          type: postEditorActions.MERGE_ATTACHMENT,
          payload: { caption },
        });
      }}
    />
  );
};

VideoEditorContainer.type = 'video-editor';

VideoEditorContainer.initialData = {
  type: AttachmentVideo.type,
  svtId: '',
  caption: '',
};

export default VideoEditorContainer;
