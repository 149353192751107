import { useContext } from 'react';

import Button from 'components/Button/Button';
import ImageEditorContainer from './ImageEditor/ImageEditorContainer';
import LegacyVideoEditorContainer from './LegacyVideoEditor/LegacyVideoEditorContainer';
import VideoEditorContainer from './VideoEditor/VideoEditorContainer';
import WebpageEditorContainer from './WebpageEditor/WebpageEditorContainer';
import QuickVideoEditorContainer from './QuickVideoEditor/QuickVideoEditorContainer';
import s from './NewAttachmentSelector.module.scss';
import {
  PostEditorContext,
  postEditorActions,
} from 'components/PostEditor/PostEditorStore';
import UserContext from 'contexts/UserContext';
import useDisasterStream from 'hooks/useDisasterStream';
import { useParams } from 'react-router-dom';
import useIsVideoUploadEnabled from 'hooks/useIsVideoUploadEnabled';

const initialDataMap = {
  [ImageEditorContainer.type]: ImageEditorContainer.initialData,
  [WebpageEditorContainer.type]: WebpageEditorContainer.initialData,
  [LegacyVideoEditorContainer.type]: LegacyVideoEditorContainer.initialData,
  [QuickVideoEditorContainer.type]: QuickVideoEditorContainer.initialData,
  [VideoEditorContainer.type]: VideoEditorContainer.initialData,
};

const getAttachments = (streamId, uploadVideoEnabled) => {
  const quickVideoEnabled = (
    process.env.REACT_APP_ENABLE_QUICK_VIDEO_FOR_STREAMS || ''
  )
    .split(',')
    .includes(streamId);

  return {
    disaster: [
      {
        type: ImageEditorContainer.type,
        text: 'Bild',
      },
    ],
    adminOrEditor: [
      {
        type: ImageEditorContainer.type,
        text: 'Bild',
      },
      ...(uploadVideoEnabled
        ? [
            {
              type: VideoEditorContainer.type,
              text: 'Video',
            },
          ]
        : [
            {
              type: LegacyVideoEditorContainer.type,
              text: 'Video',
            },
          ]),
      ...(quickVideoEnabled
        ? [
            {
              type: QuickVideoEditorContainer.type,
              text: 'Expressvideo',
            },
          ]
        : []),
      {
        type: WebpageEditorContainer.type,
        text: 'Artikel',
      },
    ],
    guest: [
      {
        type: ImageEditorContainer.type,
        text: 'Lägg till bild',
      },
    ],
  };
};

const NewAttachmentSelector = () => {
  const { isAdminOrEditor } = useContext(UserContext);
  const { isDisasterStream } = useDisasterStream();
  const { streamId } = useParams();

  let attachmentType;

  if (isDisasterStream) {
    attachmentType = 'disaster';
  } else if (isAdminOrEditor) {
    attachmentType = 'adminOrEditor';
  } else {
    attachmentType = 'guest';
  }

  const uploadVideoEnabled = useIsVideoUploadEnabled();

  const attachmentButtons = getAttachments(streamId, uploadVideoEnabled)[
    attachmentType
  ];

  const { dispatcher: postEditorDispatcher } = useContext(PostEditorContext);

  const createAttachmentOfType = (type) => {
    postEditorDispatcher({
      type: postEditorActions.SET_ATTACHMENT,
      payload: initialDataMap[type],
    });
  };

  return attachmentButtons.map((attachmentButton) => (
    <Button
      className={s.attachmentButton}
      key={attachmentButton.type}
      variant="secondary"
      onClick={() => createAttachmentOfType(attachmentButton.type)}
      disabled={attachmentButton.disabled}
    >
      {attachmentButton.text}
    </Button>
  ));
};

export default NewAttachmentSelector;
