import { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import withAuthentication from 'components/withAuthentication';
import withAdminAuthorization from 'components/withAdminAuthorization';
import Module from 'components/Module/Module';
import BlockedTable from 'components/BlockedTable/BlockedTable';
import api from '../services/api';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';

import Item from 'styleguide/Item/Item';

import s from './BlockedPage.module.scss';

const sortPosts = (field, doSortAscending) => (postA, postB) => {
  const sortOrderMultiplier = doSortAscending ? 1 : -1;

  if (!postA[field] && postB[field]) {
    return -1 * sortOrderMultiplier;
  }

  if (postA[field] && !postB[field]) {
    return 1 * sortOrderMultiplier;
  }

  if (postA[field] > postB[field]) {
    return 1 * sortOrderMultiplier;
  } else {
    return -1 * sortOrderMultiplier;
  }
};

const BlockedPage = () => {
  const { streamId } = useParams();
  const [blockedForStream, setBlockedForStream] = useState([]);
  const [sortField, setSortField] = useState();
  const [doSortAscending, setDoSortAscending] = useState(true);
  const [checkedHideTestStream, setCheckedHideTestStream] = useState(false);

  const setSortFieldOrToggleDirection = useCallback(
    (newField) => {
      if (sortField === newField) {
        setDoSortAscending(!doSortAscending);
      } else {
        setSortField(newField);
      }
    },
    [doSortAscending, sortField]
  );

  const sortAndSaveData = useCallback(
    ({ data }) => {
      if (!data) {
        return false;
      }

      if (sortField) {
        data.pendingPosts.sort(sortPosts(sortField, doSortAscending));
      }
      setBlockedForStream(data.pendingPosts);
    },
    [sortField, doSortAscending]
  );

  const listBlockedAll = useCallback(async () => {
    return sortAndSaveData(
      await api.pendingPostListBlockedAll(999, checkedHideTestStream)
    );
  }, [checkedHideTestStream, sortAndSaveData]);

  const onChangeCheckedHideTestStream = useCallback(() => {
    setCheckedHideTestStream(!checkedHideTestStream);
  }, [checkedHideTestStream, setCheckedHideTestStream]);

  useEffect(() => {
    listBlockedAll();
  }, [streamId, listBlockedAll]);

  return (
    <PageContent>
      <PageMainContainer>
        <Helmet title="AI-filtrerade inlägg" />
        <PageTitle>Besökarinlägg som har flaggats av AI-filtret</PageTitle>
        <div className={s.link}>
          <a href="/reported">Till inlägg som har flaggats av redaktörer</a>
        </div>

        <div className={s.wrapper}>
          <Module>
            {!streamId && (
              <>
                <label>
                  <input
                    onChange={onChangeCheckedHideTestStream}
                    type="checkbox"
                    id="hideTestStreamCheckbox"
                    name="hideTestStreamCheckbox"
                    checked={checkedHideTestStream}
                  />{' '}
                  Dölj testströmmen
                </label>
              </>
            )}

            <BlockedTable
              pendingPosts={blockedForStream}
              setSortField={setSortFieldOrToggleDirection}
            ></BlockedTable>

            <Item title="Raw data">
              <pre>{JSON.stringify(blockedForStream, null, 2)}</pre>
            </Item>
          </Module>
        </div>
      </PageMainContainer>
    </PageContent>
  );
};

export default withAuthentication(withAdminAuthorization(BlockedPage));
