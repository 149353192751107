import {
  DirektcenterAttachment,
  DirektcenterImage,
  DirektcenterVideo,
  DirektcenterWebpage,
  DirektcenterQuickVideo,
} from 'Types';
import AttachmentImage from './AttachmentImage';
import AttachmentVideo from './AttachmentVideo';
import AttachmentWebpage from './AttachmentWebpage';
import AttachmentQuickVideo from './AttachmentQuickVideo';
import { FC } from 'react';

type AttachmentProps = {
  attachment: DirektcenterAttachment;
  pinned: boolean;
};

const Attachment: FC<AttachmentProps> = ({ attachment, pinned }) => {
  switch (attachment.type) {
    case AttachmentImage.type:
      return (
        <AttachmentImage
          attachment={attachment as DirektcenterImage}
          pinned={pinned}
        />
      );
    case AttachmentVideo.type:
      return (
        <AttachmentVideo
          attachment={attachment as DirektcenterVideo}
          pinned={pinned}
        />
      );
    case AttachmentWebpage.type:
      return (
        <AttachmentWebpage attachment={attachment as DirektcenterWebpage} />
      );
    case AttachmentQuickVideo.type:
      return (
        <AttachmentQuickVideo
          attachment={attachment as DirektcenterQuickVideo}
          pinned={pinned}
        />
      );

    default:
      return <div>Attachment type "{attachment.type}" not recognized!</div>;
  }
};

export default Attachment;
