import axios, { AxiosProgressEvent } from 'axios';
import { NotificationMessageType } from 'contexts/NotificationContext';
import { ReactNode } from 'react';
import api from 'services/api';
import { trackEvent } from './statistics';

export const trackVideoUploadEvent = (
  eventName: string,
  contentId: string,
  eventValues: { [key: string]: number | boolean | string } = {},
  trackingConfig: { namePrefix: string; contentType: string }
) => {
  trackEvent({
    name: `${trackingConfig.namePrefix}-upload-${eventName}`,
    content: { id: contentId, type: trackingConfig.contentType },
    eventValues,
  });
};

const uploadVideo = async (
  file: File,
  flashNotification: (message: NotificationMessageType) => void,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
  abortController: AbortController,
  isQuickVideo: boolean,
  trackingConfig: { namePrefix: string; contentType: string } = {
    namePrefix: 'quick-video',
    contentType: 'uploadingDirektcenterQuickVideo',
  }
) => {
  const flashError = (message: ReactNode) => {
    flashNotification({
      type: 'error',
      message,
    });
  };

  const contentType = file.type;
  const fileName = file.name;
  const fileExtension = fileName.split('.').pop();
  console.log('FileExtension:', fileExtension);
  console.log('FileName:', fileName);

  if (!fileExtension) {
    flashError('Filen saknar filformat. Vänligen testa en annan video');
    trackVideoUploadEvent(
      'missing-file-extension-error',
      file.name,
      {},
      trackingConfig
    );
    return;
  }

  if (abortController.signal.aborted) {
    trackVideoUploadEvent(
      'cancelled-by-user',
      file.name,
      {
        error: 'Before get video upload url',
      },
      trackingConfig
    );
    return;
  }

  const response = await api.getVideoUploadUrl(
    contentType,
    fileExtension,
    isQuickVideo
  );

  if (response.status === 'fail') {
    flashError('Något gick fel under videouppladdningen. Vänligen testa igen');
    trackVideoUploadEvent(
      'getVideoUploadUrl-fail',
      file.name,
      {},
      trackingConfig
    );
    return;
  }

  if (response.status === 'error') {
    flashError('Någonting gick fel vid videouppladdning. Vänligen testa igen');
    trackVideoUploadEvent(
      'getVideoUploadUrl-error',
      file.name,
      {},
      trackingConfig
    );
    return;
  }

  const data = response.data;

  try {
    const result = await axios.put(data.uploadUrl, file, {
      signal: abortController.signal,
      headers: {
        'Content-Type': contentType,
      },
      onUploadProgress,
    });

    if (result.data.message === 'canceled') {
      trackVideoUploadEvent(
        'cancelled-by-user',
        file.name,
        {
          error: 'Upload to bucket',
        },
        trackingConfig
      );

      return;
    }
  } catch (error) {
    trackVideoUploadEvent(
      'upload-to-bucket-error',
      file.name,
      {
        error: JSON.stringify(error),
      },
      trackingConfig
    );
    flashError('Oväntat fel vid videouppladdning. Vänligen försök igen');
    return;
  }

  return data.filePath;
};

export default uploadVideo;
