import { SECTIONS } from 'utils/streamSection';
import Select from './Select/Select';

type SectionSelectProps = {
  value: string;
  onChange: () => void;
  label: string;
  defaultValue: string;
  defaultString: string;
  required: boolean;
};

const SectionSelect = ({
  value,
  onChange,
  label,
  defaultValue,
  defaultString,
  required = false,
}: SectionSelectProps) => (
  <Select value={value} onChange={onChange} label={label} required={required}>
    <>
      <option value={defaultValue}>{defaultString}</option>
      <optgroup label="Huvudavdelningar">
        {SECTIONS.map(({ name }) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </optgroup>

      {SECTIONS.map(({ name, children }) => {
        if (children.length <= 0) {
          return null;
        }

        return (
          <optgroup key={name} label={name}>
            {children.map((child) => (
              <option key={child.name} value={child.name}>
                {child.name}
              </option>
            ))}
          </optgroup>
        );
      })}
    </>
  </Select>
);

export default SectionSelect;
