import moment from 'moment';
import { ReactComponent as SendToUser } from '../../img/guest-send.svg';
import cn from 'classnames';
import DOMPurify from 'dompurify';
import { ReactComponent as SvtInfo } from '../../img/svtinfo.svg';
import Button from 'components/Button/Button';
import RemoveSplitButton from 'components/RemoveSplitButton/RemoveSplitButton';
import TimeStamp from 'components/Timestamp';
import Loader from 'components/Loader/Loader';
import PostEditorContainer from 'components/PostEditor/PostEditorContainer';
import PendingPostEditor from 'components/PendingPostEditor/PendingPostEditor';
import ShowForAdminOrEditor from 'components/ShowForAdminOrEditor/ShowForAdminOrEditor';
import ShowForAdminOrEditorOrNonModeratedGuest from 'components/ShowForAdminOrEditorOrNonModeratedGuest/ShowForAdminOrEditorOrNonModeratedGuest';
import MoreActions from 'components/MoreActions/MoreActions';
import s from './PendingPost.module.scss';
import {
  NONE,
  EDITING,
  REPLYING,
  REPORTING_ANNOYING,
  REPORTING_THREAT,
} from 'utils/pendingPostInteractiveStates';
import {
  SAFE,
  SUSPICIOUS,
  BLOCKED,
  UNMODERATED,
} from 'utils/pendingPostModerationRatings';
import Tooltip from 'components/Tooltip/Tooltip';
import useIsDevUIEnabled from 'hooks/useIsDevUIEnabled';

type interactiveStateType =
  | typeof NONE
  | typeof EDITING
  | typeof REPLYING
  | typeof REPORTING_ANNOYING
  | typeof REPORTING_THREAT;

type PendingPostProps = {
  post: {
    createdAt: string | Date;
    text: string;
    authorName: string;
    isApprovedForGuest?: boolean;
    id: string;
    moderationRating:
      | typeof UNMODERATED
      | typeof SAFE
      | typeof SUSPICIOUS
      | typeof BLOCKED;
    moderationReason?: string;
  };
  publish: () => void;
  publishWithReply: () => void;
  update: () => void;
  remove: () => void;
  reportAnnoying: () => void;
  reportThreat: () => void;
  setIsApprovedForGuest: (arg: boolean) => void;
  isChangingApprovedForGuest: boolean;
  loading?: boolean;
  isActive?: boolean;
  streamHasGuests: boolean;
  interactiveState: interactiveStateType;
  setInteractiveState: (newState: interactiveStateType) => void;
  isAIModerationEnabled: boolean;
};

const ModerationTranslationDictionary = {
  safe: 'Säkert',
  suspicious: 'Misstänkt',
  blocked: 'Blockerat',
  unmoderated: 'Ej granskat',
};

const PendingPost = ({
  post,
  publish,
  publishWithReply,
  update,
  remove,
  reportAnnoying,
  reportThreat,
  setIsApprovedForGuest,
  isChangingApprovedForGuest,
  interactiveState,
  setInteractiveState,
  isActive,
  loading,
  streamHasGuests,
  isAIModerationEnabled,
}: PendingPostProps) => {
  const PotentialReportAnnoyingWarning = interactiveState ===
    REPORTING_ANNOYING && (
    <div className={s.reportedMessageContainer}>
      <div className={s.reportedMessage}>
        <div className={s.reportedMessageHeading}>
          Det här är ett störande eller kränkande inlägg.
        </div>
        <div className={s.reportedMessageBlockTime}>Blockera i 24 timmar.</div>
        <div className={s.reportedMessageButtons}>
          <Button
            className={s.button}
            variant="light"
            onClick={() => setInteractiveState(NONE)}
          >
            Avbryt
          </Button>
          <Button
            className={s.button}
            variant="primary"
            onClick={() => reportAnnoying()}
          >
            Blockera
          </Button>
        </div>
      </div>
    </div>
  );

  const PotentialReportThreatWarning = interactiveState ===
    REPORTING_THREAT && (
    <div className={cn(s.reportedMessageContainer, s.reportedMessageThreat)}>
      <div className={s.reportedMessage}>
        <div className={s.reportedMessageHeading}>
          Anmäl som hot till SVT:s säkerhetsavdelning.
        </div>
        <div className={s.reportedMessageUrgent}>Om akut: ring 08-7842409.</div>
        <div className={s.reportedMessageBlockTime}>Blockera i 72 timmar.</div>
        <div className={s.reportedMessageButtons}>
          <Button
            className={s.button}
            variant="light"
            onClick={() => setInteractiveState(NONE)}
          >
            Avbryt
          </Button>
          <Button
            className={s.button}
            variant="primary"
            onClick={() => reportThreat()}
          >
            Anmäl &amp; blockera
          </Button>
        </div>
      </div>
    </div>
  );

  const PotentialMoreActions = !(interactiveState === EDITING) &&
    !(interactiveState === REPLYING) &&
    isActive && (
      <div className={s.actionsWrapper}>
        <MoreActions
          id={post.id}
          buttonLabel="Hantera inlägg"
          actions={[
            {
              title: 'Redigera',
              callback: () => setInteractiveState(EDITING),
            },
          ]}
        />
      </div>
    );

  const isDevUIEnabled = useIsDevUIEnabled();

  const isModerationBadgeVisible =
    isDevUIEnabled ||
    (isAIModerationEnabled && post.moderationRating === BLOCKED);

  const PotentialPost = !(interactiveState === EDITING) && (
    <div
      className={cn({
        [s.body]: true,
      })}
    >
      {PotentialMoreActions && (
        <ShowForAdminOrEditor>{PotentialMoreActions}</ShowForAdminOrEditor>
      )}

      <div
        className={s.message}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.text) }}
      />
      <ul className={s.meta}>
        <li>
          <span>{post.authorName}</span>
        </li>
        <li>
          <span>
            <TimeStamp variant="svt" mDate={moment(post.createdAt)} />
          </span>
        </li>

        {isModerationBadgeVisible && (
          <li className={s.moderationRating}>
            <div
              className={cn({
                [s.moderation]: true,
                [s.safe]: post.moderationRating === SAFE,
                [s.suspicious]: post.moderationRating === SUSPICIOUS,
                [s.blocked]: post.moderationRating === BLOCKED,
              })}
            >
              {ModerationTranslationDictionary[post.moderationRating]}
              {post.moderationReason && (
                <>
                  <div className={s.moderationInfo}>
                    <SvtInfo />
                  </div>
                  <Tooltip className={s.tooltip}>
                    {post.moderationReason}
                  </Tooltip>
                </>
              )}
            </div>
          </li>
        )}
      </ul>
    </div>
  );

  const PotentialSendToUser = streamHasGuests && (
    <ShowForAdminOrEditor>
      <Button
        className={cn(s.button, {
          [s.busy]: isChangingApprovedForGuest,
        })}
        variant={post.isApprovedForGuest ? 'active' : 'light'}
        tooltip={
          post.isApprovedForGuest ? 'Dölj för gäster' : 'Skicka till gäster'
        }
        round
        onClick={() => setIsApprovedForGuest(!post.isApprovedForGuest)}
      >
        <SendToUser />
      </Button>
    </ShowForAdminOrEditor>
  );

  const PotentialToolbar = !(interactiveState === REPLYING) &&
    !(interactiveState === EDITING) &&
    isActive && (
      <div className={s.toolbar} role="toolbar">
        <div className={s.toolbarButtonsLeft}>
          <ShowForAdminOrEditor>
            <RemoveSplitButton
              remove={remove}
              postId={post.id}
              reportAnnoying={() => setInteractiveState(REPORTING_ANNOYING)}
              reportThreat={() => setInteractiveState(REPORTING_THREAT)}
            />
          </ShowForAdminOrEditor>
        </div>
        <div className={s.toolbarButtonsRight}>
          {PotentialSendToUser}

          <Button
            className={s.button}
            variant="light"
            onClick={() => setInteractiveState(REPLYING)}
          >
            Svara
          </Button>

          <ShowForAdminOrEditorOrNonModeratedGuest>
            <Button className={s.button} variant="primary" onClick={publish}>
              Publicera
            </Button>
          </ShowForAdminOrEditorOrNonModeratedGuest>
        </div>
      </div>
    );

  const PotentialCreateReplyEditor = interactiveState === REPLYING &&
    isActive && (
      <div className={s.replyEditor}>
        <PostEditorContainer
          onSubmit={publishWithReply}
          onCancel={() => setInteractiveState(NONE)}
          submitText="Publicera"
          cancelText="Avbryt"
          attachmentEnabled={false}
          isAuthorEditable={true}
        />
      </div>
    );

  const PotentialUpdatePostEditor = interactiveState === EDITING &&
    isActive && (
      <div className={s.updatePostEditorWrapper}>
        <PendingPostEditor
          onSubmit={update}
          onCancel={() => setInteractiveState(NONE)}
          post={post}
        />
      </div>
    );

  return (
    <article className={s.root}>
      {PotentialReportAnnoyingWarning}
      {PotentialReportThreatWarning}
      <div
        className={cn(s.loadingWrapper, { [s.loadingWrapperVisible]: loading })}
      >
        <Loader absoluteCenter />
      </div>
      {PotentialPost}
      {PotentialToolbar}
      {PotentialUpdatePostEditor}
      {PotentialCreateReplyEditor}
    </article>
  );
};

export default PendingPost;
