import Input from 'components/Input/Input';
import { useContext } from 'react';
import s from './SelectVideoSource.module.scss';
import uploadVideo, { trackVideoUploadEvent } from 'utils/uploadVideo';
import MediaInfo, { AudioTrack, GeneralTrack, VideoTrack } from 'mediainfo.js';
import NotificationContext from 'contexts/NotificationContext';
import checkIfVideoIsPublished from 'utils/checkIfVideoIsPublished';
import { useParams } from 'react-router-dom';
import useStream from 'hooks/useStream';
import { Stream } from 'stream';
import { DirektcenterVideoOrigin, isStream } from 'Types';
import { getDksPrefix } from 'utils/streamSection';
import api from 'services/api';

const TRACKING_CONFIG = {
  namePrefix: 'video',
  contentType: 'uploadingDirektcenterVideo',
};

const getFileMetadata = async (file: File) => {
  const mediaInfo = await MediaInfo({
    locateFile: () =>
      `${process.env.REACT_APP_EDITOR_URL}/MediaInfoModule-0_3_2.wasm`,
  });

  const result = await mediaInfo.analyzeData(
    file.size,
    async (chunkSize, offset) => {
      const buffer = await file.slice(offset, offset + chunkSize).arrayBuffer();
      return new Uint8Array(buffer);
    }
  );
  mediaInfo.close();

  const generalMetadata = result.media
    ? result.media.track.find((track) => track['@type'] === 'General')
    : null;

  const videoMetadata = result.media
    ? result.media.track.find((track) => track['@type'] === 'Video')
    : null;

  const audioMetadata = result.media
    ? result.media.track.find((track) => track['@type'] === 'Audio')
    : null;

  if (generalMetadata && videoMetadata && audioMetadata) {
    const metadata = {
      general: generalMetadata as GeneralTrack,
      video: videoMetadata as VideoTrack,
      audio: audioMetadata as AudioTrack,
    };

    return metadata;
  }
};

const getVideoValidationMessage = (
  metadata:
    | {
        general: GeneralTrack;
        video: VideoTrack;
        audio: AudioTrack;
      }
    | undefined
) => {
  if (!metadata) {
    return 'Ogiltig video, försök med en annan video';
  }

  return undefined;
};

const shouldVideoCrop = (
  width: number | undefined,
  height: number | undefined
) => {
  if (!width || !height) {
    throw new Error('Width or height was not provided!');
  }

  if (width / height === 16 / 9 || width / height === 9 / 16) {
    return false;
  }

  return true;
};

type SelectVideoSourceProps = {
  value: string;
  onChange: (svtId: string, origin: DirektcenterVideoOrigin) => void;
  inputError?: string;
  setLoadingVideoData: (loading: boolean) => void;
  setVideoBlobUrl: (url: string | null) => void;
  setProgress: (progress: number) => void;
  abortController: AbortController;
};

const SelectVideoSource = ({
  value,
  onChange,
  inputError,
  setLoadingVideoData,
  setVideoBlobUrl,
  setProgress,
  abortController,
}: SelectVideoSourceProps) => {
  const { flashNotification, flashError } = useContext(NotificationContext);
  const { streamId } = useParams() as { streamId: string };
  const streamData = useStream(streamId);
  const stream = streamData[0] as Stream | {};

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingVideoData(true);

    if (e.target.files && isStream(stream)) {
      const file = e.target.files[0];

      setVideoBlobUrl(URL.createObjectURL(file));

      const fileMetadata = await getFileMetadata(file);

      const shouldCrop = shouldVideoCrop(
        fileMetadata?.video?.Width,
        fileMetadata?.video?.Height
      );

      const validationMessage = getVideoValidationMessage(fileMetadata);

      if (validationMessage) {
        trackVideoUploadEvent(
          'validation-failed',
          file.name,
          {
            validationMessage,
          },
          TRACKING_CONFIG
        );
        flashError(validationMessage);
        setLoadingVideoData(false);
        setVideoBlobUrl(null);
        return fileMetadata;
      }

      const isQuickVideo = false;

      const fileName = await uploadVideo(
        file,
        flashNotification,
        (progressEvent) => {
          const percentCompleted =
            progressEvent.total &&
            Math.round((progressEvent.loaded * 100) / progressEvent.total);

          percentCompleted && setProgress(percentCompleted);
        },
        abortController,
        isQuickVideo
      );

      if (!fileName) {
        setLoadingVideoData(false);
        return fileMetadata;
      }

      const triggerVideoEncodingResponse = await api.triggerVideoEncoding(
        fileName,
        getDksPrefix(stream.parentSection, stream.section) || 'RW',
        shouldCrop,
        {
          streamId,
          streamTitle: stream.title,
          videoTitle: `En video i "${stream.title}"`,
          isCropped: shouldCrop,
        }
      );

      console.log(
        'TriggerVideoEncodingResponse:',
        triggerVideoEncodingResponse
      );

      if (triggerVideoEncodingResponse.status !== 'success') {
        setVideoBlobUrl(null);
        setLoadingVideoData(false);
        flashError(
          'Något gick fel vid initiering av video encoding, försök igen'
        );

        return fileMetadata;
      }

      const svtId = triggerVideoEncodingResponse.data.svtId;

      const uploadStatus = await checkIfVideoIsPublished(
        svtId,
        abortController
      );

      trackVideoUploadEvent(
        'video-encoding-triggered',
        fileName,
        {
          svtId,
          fileName,
          shouldCrop,
          width: fileMetadata?.video.Width || 'unknown_width',
          height: fileMetadata?.video.Height || 'unknown_height',
          rotation: fileMetadata?.video.Rotation || 'unknown_rotation',
        },
        TRACKING_CONFIG
      );

      setVideoBlobUrl(null);
      setLoadingVideoData(false);

      if (uploadStatus !== 'SUCCESS') {
        if (uploadStatus === 'FAILED') {
          flashError('Något gick fel vid uppladdning av video, försök igen');
        }

        return fileMetadata;
      }

      onChange(svtId, 'direktcenter');

      flashNotification({
        type: 'success',
        message: 'Video redo för publicering',
      });

      return fileMetadata;
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const timeElapsed = Date.now();
    const metadata = await uploadFile(e);

    const uploadTime = (Date.now() - timeElapsed) / 1000;
    const videoDuration = metadata?.video.Duration || 'unknown_duration';
    const fileSize = metadata?.general.FileSize || 'unknown_size';
    const fileName =
      (e.target.files && e.target.files[0].name) || 'unknown_filename';

    trackVideoUploadEvent(
      'completed',
      fileName,
      {
        uploadTime,
        videoDuration,
        fileSize,
      },
      TRACKING_CONFIG
    );
  };

  return (
    <div className={s.selectRoot}>
      <div className={s.fileInputWrapper}>
        <input
          autoFocus
          className={s.fileInput}
          onChange={(event) => {
            handleUploadFile(event);
          }}
          type="file"
          accept=".mov, .mp4, video/quicktime, video/mp4"
          required
          id="videoFileInputId"
        />
        <label className={s.fileInputLabel} htmlFor="videoFileInputId">
          <div className={s.uploadFileInfoDescription}>Ladda upp video</div>
          <div className={s.uploadFileInfoDescriptionList}>
            Filstorleken påverkar uppladdningstiden
          </div>
        </label>
      </div>
      <p className={s.orLabel}>eller</p>
      <Input
        label="Skriv in Video ID"
        type="text"
        placeholder="T.ex: eWmAG7J"
        value={value}
        onChange={(e) => {
          const svtId = e.target.value;
          onChange(svtId, 'other');
        }}
        error={inputError}
      />
    </div>
  );
};

export default SelectVideoSource;
