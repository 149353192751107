import { useEffect, useMemo, useState } from 'react';
import Attachment from 'components/Attachment/Attachment';
import Loader from 'components/Loader/Loader';
import s from './VideoEditor.module.scss';
import SelectVideoSource from './SelectVideoSource/SelectVideoSource';
import Input from 'components/Input/Input';
import { DirektcenterVideoOrigin } from 'Types';

const progressMessage = (progress: number) => {
  if (progress === 0) {
    return 'Laddar video...';
  } else if (progress > 0 && progress <= 99) {
    return `Laddar video... ${progress}%`;
  } else {
    return `Bearbetar video...`;
  }
};

type VideoEditorProps = {
  inputSvtId: string;
  handleSvtIdChange: (svtId: string, origin: DirektcenterVideoOrigin) => void;
  inputError?: string;
  attachment: any;
  loadingVideoData: boolean;
  setLoadingVideoData: (loading: boolean) => void;
  caption: string;
  setInputCaption: (caption: string) => void;
};

const VideoEditor = ({
  inputSvtId,
  handleSvtIdChange,
  inputError,
  attachment,
  loadingVideoData,
  setLoadingVideoData,
  caption,
  setInputCaption,
}: VideoEditorProps) => {
  const [videoBlobUrl, setVideoBlobUrl] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  const abortController = useMemo(() => new AbortController(), []);

  useEffect(() => {
    return () => {
      abortController.abort();
      setLoadingVideoData(false);
    };
  }, [setLoadingVideoData, abortController]);

  const displayVideoCaption =
    (Boolean(inputSvtId) || loadingVideoData) && !inputError;

  return (
    <div>
      {!attachment && !loadingVideoData && (
        <SelectVideoSource
          value={inputSvtId}
          onChange={handleSvtIdChange}
          inputError={inputError}
          setLoadingVideoData={setLoadingVideoData}
          setVideoBlobUrl={setVideoBlobUrl}
          setProgress={setProgress}
          abortController={abortController}
        />
      )}
      {attachment && !loadingVideoData && (
        <Attachment attachment={attachment} pinned={false} />
      )}
      {loadingVideoData && (
        <div className={s.videoWrapper}>
          <div className={s.videoOverlay}>
            <Loader padding={false}>
              <p className={s.videoOverlayText}>{progressMessage(progress)}</p>
            </Loader>
          </div>
          {videoBlobUrl && (
            <video
              width="100%"
              height="auto"
              muted={true}
              playsInline={true}
              controls={false}
              preload={'auto'}
              autoPlay={true}
              src={videoBlobUrl}
              loop={true}
              className={s.video}
            ></video>
          )}
        </div>
      )}
      {displayVideoCaption && (
        <Input
          label="Videotext"
          type="text"
          placeholder="Beskriv videons innehåll"
          autoFocus
          value={caption}
          onChange={(e) => setInputCaption(e.target.value)}
          error={inputError}
        />
      )}
    </div>
  );
};

export default VideoEditor;
