import UserContext from 'contexts/UserContext';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * This hook is used to enable the dev UI for admin users.
 * The dev state is enabled by appending the dev query parmeter like this: http://localhost:3002/streams/wUZUYrVDHVR5MnvHVoI5?devtools
 */
const useIsDevUIEnabled = () => {
  const { isAdmin } = useContext(UserContext);
  const [params] = useSearchParams();
  const isDevToolsParamsSet = params.get('devtools') !== null;

  return isAdmin && isDevToolsParamsSet;
};

export default useIsDevUIEnabled;
