import useStream from './useStream';
import { useParams } from 'react-router-dom';
import { isStream } from '../Types';

const useIsVideoUploadEnabled = () => {
  const { streamId } = useParams();
  const [stream] = useStream(streamId);

  if (!isStream(stream) || !streamId) {
    return false;
  }

  const isVideoUploadEnabledForSection = [
    'Riks',
    'Lokalt',
    'Sport',
    'Test',
  ].includes(stream.parentSection);

  const uploadVideoEnabledForStream = (
    process.env.REACT_APP_ENABLE_UPLOAD_VIDEO_FOR_STREAMS || ''
  )
    .split(',')
    .includes(streamId);

  return uploadVideoEnabledForStream || isVideoUploadEnabledForSection;
};

export default useIsVideoUploadEnabled;
