import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import PendingPostStream from './PendingPostStream';
import usePauseManager from './usePauseManager';
import usePaginatedPendingPostStream from 'hooks/usePaginatedPendingPostStream';
import { HIDE_BLOCKED, SHOW_ALL } from 'utils/pendingPostFilter';
import UserContext from 'contexts/UserContext';
import useIsDevUIEnabled from 'hooks/useIsDevUIEnabled';

const PendingPostStreamContainer = ({
  streamId,
  isActive,
  isVisitorPostingEnabled,
}) => {
  const { isAdminOrEditor } = useContext(UserContext);

  const aiModerationStreamIds =
    (process.env.REACT_APP_AI_MODERATION_STREAM_IDS &&
      process.env.REACT_APP_AI_MODERATION_STREAM_IDS.split(',').map(
        (streamId) => streamId.trim()
      )) ||
    [];

  const isAIModerationAllStreamsToggleEnabled = Boolean(
    Number(process.env.REACT_APP_AI_MODERATION_ALL_STREAMS)
  );

  const isAIModerationToggleEnabled = Boolean(
    Number(process.env.REACT_APP_AI_MODERATION_ENABLED)
  );

  const isAIModerationEnabled =
    isAIModerationToggleEnabled &&
    (isAIModerationAllStreamsToggleEnabled ||
      aiModerationStreamIds.includes(streamId)) &&
    isAdminOrEditor;

  const isDevUIEnabled = useIsDevUIEnabled();

  // TODO: Remove these console.logs when we are confident that the AI feature is working as expected
  if (isDevUIEnabled) {
    console.log('aiModerationStreamIds', aiModerationStreamIds);
    console.log(
      'isAIModerationAllStreamsToggleEnabled',
      isAIModerationAllStreamsToggleEnabled
    );
    console.log('isAIModerationToggleEnabled', isAIModerationToggleEnabled);
    console.log('isAIModerationEnabled', isAIModerationEnabled);
  }

  const pauseManager = usePauseManager();
  const [activeFilter, setActiveFilter] = useState(
    isAIModerationEnabled ? HIDE_BLOCKED : SHOW_ALL
  );

  const paginatedStream = usePaginatedPendingPostStream({
    streamId,
    isPaused: pauseManager.pauseState !== 'ACTIVE',
    postsPerPage: 20,
    activeFilter,
  });

  return (
    <PendingPostStream
      pauseManager={pauseManager}
      paginatedStream={paginatedStream}
      streamId={streamId}
      isActive={isActive}
      isVisitorPostingEnabled={isVisitorPostingEnabled}
      isAIModerationEnabled={isAIModerationEnabled}
      activeFilter={activeFilter}
      onChangeActiveFilter={(val) => {
        return setActiveFilter(val);
      }}
    />
  );
};

PendingPostStreamContainer.propTypes = {
  streamId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isVisitorPostingEnabled: PropTypes.bool.isRequired,
};

export default PendingPostStreamContainer;
