import { Helmet } from 'react-helmet';
import withAuthentication from 'components/withAuthentication';
import withGuestAuthorization from 'components/withGuestAuthorization';
import PostStreamContainer from 'components/PostStream/PostStreamContainer';
import StreamMeta from 'components/StreamMeta/StreamMeta';
import PostEditorContainer from 'components/PostEditor/PostEditorContainer';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';
import { ReactComponent as SvtInfo } from 'img/svtinfo.svg';
import { ReactComponent as EditPen } from 'img/edit-pen.svg';
import { ReactComponent as LiveVideo } from 'img/live-video-icon.svg';

import { DirektcenterPost, Stream, StreamId, StreamLiveBroadcast } from 'Types';
import { useContext, useEffect, useRef } from 'react';
import NotificationContext from 'contexts/NotificationContext';

import api from 'services/api';
import s from './PostingPage.module.scss';
import Switch from 'components/Switch/Switch';
import { useState } from 'react';
import DisasterLiveBroadcastModal from './DisasterLiveBroadcastModal';
import Tooltip from 'components/Tooltip/Tooltip';
import PendingPostsPoller from 'services/Stream/PendingPostsPoller';
import UserContext from 'contexts/UserContext';
import { trackEvent } from 'utils/statistics';
import { HIDE_BLOCKED } from 'utils/pendingPostFilter';

const DEFAULT_LIVE_BROADCAST_MANIFEST =
  'https://svt-news.akamaized.net/se/news/master.m3u8?defaultSubLang=1';

type DisasterPostingPageProps = {
  stream: Stream;
  loading: boolean;
  streamId: StreamId;
  handleStreamDeleted: () => void;
  createPost: (post: DirektcenterPost) => void;
};

const DisasterPostingPage = ({
  stream,
  loading,
  streamId,
  handleStreamDeleted,
  createPost,
}: DisasterPostingPageProps) => {
  const pendingPostStream = useRef<ReturnType<
    typeof PendingPostsPoller
  > | null>(null);

  const { user } = useContext(UserContext);

  useEffect(() => {
    pendingPostStream.current = PendingPostsPoller({
      streamId,
      postsPerPage: 1,
      api,
      activeFilter: HIDE_BLOCKED,
    });

    const onError = () => {
      pendingPostStream?.current?.unsubscribe();
    };

    pendingPostStream.current.listen(() => null, onError);

    return pendingPostStream.current.unsubscribe;
  }, [streamId]);

  const { flashError, flashNotification } = useContext(NotificationContext);

  const [showDisasterLiveBroadcastModal, setShowDisasterLiveBroadcastModal] =
    useState(false);

  const streamUpdate = async (
    liveBroadcast: StreamLiveBroadcast,
    fromManifestModal: boolean
  ) => {
    const response = await api.streamUpdate(streamId, {
      ...stream,
      isHighlightsBoxVisible: false,
      isVisitorPostingEnabled: false,
      liveBroadcast,
    });

    if (response.status === 'success') {
      const fromModalMessage = `Livesändningen är uppdaterad till ${
        liveBroadcast.manifest === DEFAULT_LIVE_BROADCAST_MANIFEST
          ? 'LP1'
          : liveBroadcast.manifest
      }`;

      const fromToggleMessage = `Livesändningen ${
        liveBroadcast.enabled ? 'är aktiv' : 'är inaktiv'
      }`;

      const message = fromManifestModal ? fromModalMessage : fromToggleMessage;

      flashNotification({
        message,
        type: 'success',
      });
    } else {
      const fromModalError = `Kunde inte uppdatera Livesändningen`;
      const fromToggleError = `Kunde inte ${
        liveBroadcast.enabled ? 'aktivera' : 'inaktivera'
      } Livesändningen`;

      const error = fromManifestModal ? fromModalError : fromToggleError;
      flashError(error);
    }
  };

  const handleModalSubmit = async (manifest: string) => {
    setShowDisasterLiveBroadcastModal(false);

    const liveBroadcast: StreamLiveBroadcast = {
      enabled: Boolean(stream.liveBroadcast?.enabled),
      manifest,
    };

    trackEvent({
      name: 'disaster-modal-submit',
      eventValues: {
        userId: user?.id,
        userEmail: user?.email,
        ...liveBroadcast,
      },
    });

    await streamUpdate(liveBroadcast, true);
  };

  const handleLiveManifestToggle = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const liveBroadcastEnabled = e.target.checked;

    const liveBroadcast = {
      enabled: liveBroadcastEnabled,
      manifest:
        stream.liveBroadcast?.manifest || DEFAULT_LIVE_BROADCAST_MANIFEST,
    };

    trackEvent({
      name: liveBroadcastEnabled
        ? 'disaster-activate-live-broadcast'
        : 'disaster-deactivate-live-broadcast',
      eventValues: {
        userId: user?.id,
        userEmail: user?.email,
        ...liveBroadcast,
      },
    });

    await streamUpdate(liveBroadcast, false);
  };

  return (
    <PageContent withAside>
      <Helmet title={stream.title} />
      <PageMainContainer loading={loading} withAside>
        <div className={s.root}>
          <div className={s.streamMeta}>
            <StreamMeta
              stream={stream}
              streamId={streamId}
              onStreamDeleted={handleStreamDeleted}
            />
          </div>

          <div className={s.disasterstreamMessageWrapper}>
            <SvtInfo className={s.infoIcon} />
            <div className={s.disasterstreamMessage}>
              <div>
                Denna ström används av <strong>nödsajten</strong> när nödsajten
                är aktiverad på{' '}
                <a href="https://www.svt.se" target="blank">
                  svt.se
                </a>
                .
              </div>
            </div>
          </div>

          <div className={s.toggleLiveBroadcastWrapper}>
            <div className={s.toggleLiveBroadcastLeft}>
              <div className={s.liveBadge}>LIVE</div>
              <div className={s.toggleLiveBroadcastHeadlineWrapper}>
                <div className={s.toggleLiveBroadcastHeadline}>
                  <LiveVideo className={s.liveVideoIcon} />
                  Livesändning
                </div>
              </div>
              <label className={s.toggleLiveBroadcastSubline}>
                <span>
                  Sändning:{' '}
                  {stream.liveBroadcast?.manifest ===
                  DEFAULT_LIVE_BROADCAST_MANIFEST
                    ? 'Standard LP1'
                    : 'Annan'}
                </span>
                <button
                  className={s.toggleLiveBroadcastSublineButton}
                  onClick={() => {
                    setShowDisasterLiveBroadcastModal(true);
                  }}
                >
                  <EditPen className={s.editPen} />
                </button>
                <Tooltip className={s.tooltip}>{'Välj livesändning'}</Tooltip>
              </label>
            </div>
            <div className={s.switchWrapper}>
              <Switch
                onLabel={`Inaktivera`}
                offLabel={`Aktivera`}
                onChange={(e) => {
                  handleLiveManifestToggle(e);
                }}
                variant="green"
                labelLeft={true}
                checked={Boolean(stream.liveBroadcast?.enabled)}
              />
            </div>
          </div>

          <div className={s.createPostEditor}>
            <h2 className="visuallyhidden">Skriv inlägg</h2>
            <PostEditorContainer
              onSubmit={createPost}
              submitText={stream.isActive ? 'Publicera' : 'Spara'}
              attachmentEnabled={true}
              bigSubmit={true}
              isAuthorEditable={true}
            />
          </div>
          <PostStreamContainer
            streamId={streamId}
            isEditable={true}
            filterHighlighted={false}
            isHighlightsFeatureEnabled={false}
          />
        </div>
      </PageMainContainer>
      <DisasterLiveBroadcastModal
        visible={showDisasterLiveBroadcastModal}
        currentManifest={stream.liveBroadcast?.manifest}
        defaultManifest={DEFAULT_LIVE_BROADCAST_MANIFEST}
        onSubmit={(manifest) => {
          handleModalSubmit(manifest);
        }}
        onCancel={() => {
          setShowDisasterLiveBroadcastModal(false);
        }}
      />
    </PageContent>
  );
};

export default withAuthentication(withGuestAuthorization(DisasterPostingPage));
