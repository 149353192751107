import { useContext } from 'react';

import NotificationContext from 'contexts/NotificationContext';
import api from '../services/api';
import { DirektcenterPost, DirektcenterVideoOrigin } from 'Types';
import { trackEvent } from 'utils/statistics';
import useIsVideoUploadEnabled from 'hooks/useIsVideoUploadEnabled';

const useCreatePost = (streamId: string) => {
  const { flashNotification } = useContext(NotificationContext);

  const uploadVideoEnabled = useIsVideoUploadEnabled();

  const createPost = async (post: DirektcenterPost) => {
    const response = await api.postCreate(streamId, {
      body: post.body,
      attachment: post.attachment,
      pinned: false,
      highlighted: false,
      author: post.author,
    });
    if (response.status !== 'success') {
      flashNotification({
        type: 'error',
        message: 'Kunde inte skapa ditt inlägg. Vänligen försök igen',
      });
      return false;
    }

    if (
      uploadVideoEnabled &&
      post.attachment &&
      post.attachment.type === 'svt-video'
    ) {
      trackEvent({
        name: `direktcenter-created-post-with-video-attachment`,
        content: { id: streamId, type: post.attachment.type },
        eventValues: {
          origin: post.attachment.origin as DirektcenterVideoOrigin, // All new or updated
        },
      });
    }

    return true;
  };

  return createPost;
};

export default useCreatePost;
