import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import PendingPostContainer from 'components/PendingPost/PendingPostContainer';
import {
  NONE,
  EDITING,
  REPLYING,
  REPORTING_ANNOYING,
  REPORTING_THREAT,
} from 'utils/pendingPostInteractiveStates';

const PendingPostList = ({
  posts,
  streamId,
  isActive,
  streamHasGuests,
  pendingPostsInteractiveState,
  setInteractiveState,
  isAIModerationEnabled,
}) => {
  const getStateForPost = useCallback(
    (postId) => {
      const postStateObject = pendingPostsInteractiveState.find(
        (state) => state.pendingPostId === postId
      );

      if (postStateObject) {
        return postStateObject.pendingPostInteractiveState;
      }

      return NONE;
    },
    [pendingPostsInteractiveState]
  );

  return (
    <div>
      {posts
        .map((post) => (
          <PendingPostContainer
            key={post.id}
            post={post}
            streamId={streamId}
            isActive={isActive}
            streamHasGuests={streamHasGuests}
            interactiveState={getStateForPost(post.id)}
            setInteractiveState={(interactiveState) =>
              setInteractiveState(post.id, interactiveState)
            }
            isAIModerationEnabled={isAIModerationEnabled}
          />
        ))
        .filter(Boolean)}
    </div>
  );
};

PendingPostList.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  streamId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  streamHasGuests: PropTypes.bool.isRequired,
  pendingPostsInteractiveState: PropTypes.arrayOf(
    PropTypes.shape({
      pendingPostId: PropTypes.string.isRequired,
      pendingPostInteractiveState: PropTypes.oneOf([
        NONE,
        EDITING,
        REPLYING,
        REPORTING_ANNOYING,
        REPORTING_THREAT,
      ]),
    })
  ),
  setInteractiveState: PropTypes.func.isRequired,
  isAIModerationEnabled: PropTypes.bool.isRequired,
};

export default PendingPostList;
