import { SectionDataType } from 'Types';

interface StreamSectionChild {
  name: string;
  dksPrefix?: string;
}

interface StreamSection {
  name: string;
  children: readonly StreamSectionChild[];
  dksPrefix: string;
}

export const SECTIONS: readonly StreamSection[] = Object.freeze([
  {
    name: 'Riks',
    children: [
      { name: 'Nyhetstecken', dksPrefix: 'LK' },
      { name: 'Ođđasat', dksPrefix: 'OD' },
      { name: 'Uutiset', dksPrefix: 'UU' },
      { name: 'Väder' }, // ME = Meteorologi Raderas efter 8 veckor, kanske inte vi vill?
    ],
    dksPrefix: 'RW',
  },
  {
    name: 'Sport',
    children: [],
    dksPrefix: 'SP',
  },
  {
    name: 'Duo',
    children: [
      { name: 'Fråga doktorn' },
      { name: `Go'kväll` },
      { name: 'Lilla Aktuellt', dksPrefix: 'LA' },
      { name: 'Melodifestivalen' },
      { name: 'Musikhjälpen' },
      { name: 'Älgvandringen' },
    ],
    dksPrefix: 'PG',
  },
  {
    name: 'Lokalt',
    children: [
      { name: 'Blekinge', dksPrefix: 'BL' },
      { name: 'Dalarna', dksPrefix: 'F' },
      { name: 'Gävleborg', dksPrefix: 'GA' },
      { name: 'Halland', dksPrefix: 'H' },
      { name: 'Helsingborg', dksPrefix: 'HB' },
      { name: 'Jämtland', dksPrefix: 'OS' },
      { name: 'Jönköping', dksPrefix: 'J' },
      { name: 'Norrbotten', dksPrefix: 'L' },
      { name: 'Skåne', dksPrefix: 'M' },
      { name: 'Småland', dksPrefix: 'X' },
      { name: 'Stockholm', dksPrefix: 'C' },
      { name: 'Södertälje', dksPrefix: 'SO' },
      { name: 'Sörmland', dksPrefix: 'ES' },
      { name: 'Uppsala', dksPrefix: 'UP' },
      { name: 'Värmland', dksPrefix: 'K' },
      { name: 'Väst', dksPrefix: 'G' },
      { name: 'Västerbotten', dksPrefix: 'U' },
      { name: 'Västernorrland', dksPrefix: 'S' },
      { name: 'Västmanland', dksPrefix: 'V' },
      { name: 'Örebro', dksPrefix: 'O' },
      { name: 'Öst', dksPrefix: 'N' },
    ],
    dksPrefix: 'LW',
  },
  {
    name: 'Barn',
    children: [],
    dksPrefix: 'BK',
  },
  {
    name: 'Nödsajt',
    children: [],
    dksPrefix: 'RW',
  },
  {
    name: 'Test',
    children: [{ name: 'Regression' }],
    dksPrefix: 'ZZQ',
  },
]);

export function validateSection(sectionToValidate: string) {
  const hasSection = SECTIONS.some((section) =>
    section.children.some((child) => {
      return child.name === sectionToValidate;
    })
  );

  if (hasSection) {
    return sectionToValidate;
  }

  return '';
}

export function validateParentSection(section: string) {
  if (SECTIONS.some((o) => o.name === section)) {
    return section;
  }

  return '';
}

export function getParentSection(childSectionName: string) {
  const parentSection = SECTIONS.find((o) =>
    o.children.some((child) => {
      return child.name === childSectionName;
    })
  );

  if (parentSection) {
    return parentSection.name;
  }

  return '';
}

export function createSectionData(value: string): SectionDataType {
  return {
    parentSection: validateParentSection(value) || getParentSection(value),
    section: validateSection(value),
  };
}

export function getDksPrefix(parentSection: string, section: string) {
  const parent = SECTIONS.find((parent) => {
    const parentGotChildWithCorrectName =
      parent.children.filter((child) => child.name === section).length > 0;

    return parentGotChildWithCorrectName;
  });

  if (!parent) {
    if (parentSection) {
      const possibleParentDksPrefix = SECTIONS.find(
        (section) => section.name === parentSection
      )?.dksPrefix;

      return possibleParentDksPrefix || null;
    }

    return null;
  }

  const possibleChildDksPrefix = parent.children.find(
    (child) => child.name === section
  )?.dksPrefix;

  return possibleChildDksPrefix || parent.dksPrefix;
}
