import { useState, useEffect, useRef } from 'react';
import PendingPostsPoller from 'services/Stream/PendingPostsPoller';
import api from '../services/api';

const usePaginatedPendingPostStream = ({
  streamId,
  postsPerPage,
  isPaused,
  activeFilter,
}) => {
  const [loadingInitialPosts, setLoadingInitialPosts] = useState(true);
  const [loadingMorePosts, setLoadingMorePosts] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(false);
  const [hasGuests, setHasGuests] = useState(false);
  const [unreadPosts, setUnreadPosts] = useState(0);
  const [posts, setPosts] = useState([]);
  const pendingPostStream = useRef(null);

  const loadMorePosts = () => {
    setLoadingMorePosts(true);
    pendingPostStream.current.loadMoreItems();
  };

  useEffect(() => {
    pendingPostStream.current = PendingPostsPoller({
      streamId,
      postsPerPage,
      api,
      activeFilter,
    });

    const onPostsUpdate = (posts) => {
      setLoadingInitialPosts(false);
      setLoadingMorePosts(false);
      setHasMorePosts(pendingPostStream.current.hasMoreItems());
      setHasGuests(pendingPostStream.current.hasGuests());
      setPosts(posts);
    };

    const onError = () => {
      pendingPostStream.current.unsubscribe();
    };

    pendingPostStream.current.listen(onPostsUpdate, onError);

    return pendingPostStream.current.unsubscribe;
  }, [activeFilter, postsPerPage, streamId]);

  useEffect(() => {
    const onUnreadUpdate = (count) => {
      setHasGuests(pendingPostStream.current.hasGuests());
      setUnreadPosts(count);
    };

    const onError = () => {
      pendingPostStream.current.unsubscribeUnread();
    };

    if (isPaused) {
      pendingPostStream.current.pause();
      pendingPostStream.current.listenUnread(onUnreadUpdate, onError);
    } else {
      setUnreadPosts(0);
      pendingPostStream.current.resume();
      pendingPostStream.current.unsubscribeUnread();
    }

    return pendingPostStream.current.unsubscribeUnread;
  }, [isPaused]);

  return {
    posts,
    loadingInitialPosts,
    loadingMorePosts,
    hasMorePosts,
    hasGuests,
    loadMorePosts,
    unreadPosts,
  };
};

export default usePaginatedPendingPostStream;
