import { Timestamp } from 'firebase/firestore';

export enum DirektcenterRole {
  ADMIN = 'admin',
  EDITOR = 'editor',
  GUEST = 'guest',
  VISITOR = 'visitor',
}

export type DirektcenterRoleType = `${DirektcenterRole}`;

export type DirektcenterAvatar = {
  path: string;
};

export type DirektcenterAuthor = {
  title?: string;
  displayName: string;
  avatar: null | DirektcenterAvatar;
  role: DirektcenterRoleType;
};

// TODO: make focalPoint mandatory by using astridImageSchema.validate in fetchVideo data route in API
export type AstridImage = {
  id: string;
  focalPoint?: { x: number; y: number };
};

export type DirektcenterImage = {
  type: 'image';
  path: string;
  caption?: string;
  originalWidth: number;
  originalHeight: number;
  alt?: string;
};

export type DirektcenterVideo = {
  type: 'svt-video';
  svtId: string;
  videoId?: string | null; // TODO Escenic ID Maybe remove???
  posterImageId: string | null;
  aspectRatio?: AspectRatio;
  duration?: number;
  astridImage?: AstridImage | null;
  caption?: string;
  origin?: DirektcenterVideoOrigin;
};

export type DirektcenterVideoOrigin = 'direktcenter' | 'other';

export type DirektcenterQuickVideo = {
  type: 'quick-video';
  videoFile: string;
  posterImageFile: string;
  caption: string;
};

// Using the defined aspect ratios from the SVT video-player
export type AspectRatio = '16:9' | '9:16' | '1:1';

export type DirektcenterWebpage = {
  type: 'webpage';
  title: string;
  url: string;
  image?: string;
  escenicImageId?: string;
  escenicImageVersion?: string;
  description?: string;
  astridImage?: AstridImage | null;
};

export type DirektcenterAttachment =
  | DirektcenterImage
  | DirektcenterVideo
  | DirektcenterWebpage
  | DirektcenterQuickVideo;

export type DirektcenterPost = {
  id: PostId;
  createdAt: Timestamp;
  modifiedAt?: Timestamp;
  body: string;
  author: DirektcenterAuthor;
  attachment?: DirektcenterAttachment;
  replies?: DirektcenterPost[];
  pinned: boolean;
  highlighted?: boolean;
};

export type SectionDataType = {
  parentSection: string;
  section: string;
};
export type MenuAction = {
  title: string | JSX.Element;
  callback: () => void;
  danger?: boolean;
  disabled?: boolean;
};

export type StreamLiveBroadcast = {
  enabled: boolean;
  manifest: string;
};

export type Stream = {
  createdAt: string;
  modifiedAt?: string | null; // Can be null when coming from search endpoint
  isActive: boolean;
  parentSection: string;
  section: string;
  streamId?: string;
  title: string;
  isHighlightsBoxVisible?: boolean;
  isVisitorPostingEnabled?: boolean;
  liveBroadcast?: StreamLiveBroadcast;
};

export function isStream(obj: any): obj is Stream {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.createdAt === 'string' &&
    (typeof obj.modifiedAt === 'string' ||
      obj.modifiedAt === null ||
      obj.modifiedAt === undefined) &&
    typeof obj.isActive === 'boolean' &&
    typeof obj.parentSection === 'string' &&
    typeof obj.section === 'string' &&
    (typeof obj.streamId === 'string' || obj.streamId === undefined) &&
    typeof obj.title === 'string' &&
    (typeof obj.isHighlightsBoxVisible === 'boolean' ||
      obj.isHighlightsBoxVisible === undefined) &&
    (typeof obj.isVisitorPostingEnabled === 'boolean' ||
      obj.isVisitorPostingEnabled === undefined)
  );
}

export type User = {
  role: DirektcenterRoleType;
  email: string;
  displayName: string;
  avatar: {
    path: string;
  };
};

export type AvatarImageSize = 'small' | 'medium' | 'large' | 'extraLarge';

export type StreamId = string;
export type PostId = string;

export type StatisticsAttribute = {
  status: string;
  count: number;
};

export type Guest = {
  created_at: string;
  displayName: string;
  id: number;
  initiator: string;
  initiatorEmail: string;
  isModerationRequired: boolean;
  lastRefreshTokenCreatedAt: string;
  role: DirektcenterRole.GUEST;
  status: string;
  streamId: StreamId;
  title: string;
};

export type FirebaseCredentials = {
  email: string;
  password: string;
};
