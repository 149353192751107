import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Timestamp from 'components/Timestamp';
import Tooltip from 'components/Tooltip/Tooltip';
import s from './BlockedTable.module.scss';

const PotentialTooltip = (params) => {
  if (!params.children) {
    return null;
  }
  return Tooltip(params);
};

export default function BlockedTableRow({ pendingPost }) {
  const [doShowText, setDoShowText] = useState(false);

  if (!pendingPost) {
    return null;
  }

  const statusPresentation = {
    reportedAnnoying: <span className={s.annoying}>störande</span>,
    reportedThreat: <span className={s.threat}>hot</span>,
    blockedCustom: <span className={s.blockedCustom}>customblock</span>,
    blockedAuto: <span className={s.blockedAuto}>autoblock</span>,
    duplicate: <span className={s.blockedAuto}>upprepande</span>,
    published: <span className={s.published}>publicerad</span>,
    pending: <span className={s.pending}>opublicerad</span>,
  };

  const moderationRatingPresentation = {
    blocked: <span className={s.blocked}>blocked</span>,
    suspicious: <span className={s.suspicious}>suspicious</span>,
    unmoderated: <span className={s.unmoderated}>unmoderated</span>,
    safe: <span className={s.safe}>safe</span>,
  };

  const mCreatedAt = moment(pendingPost.createdAt);
  return (
    <React.Fragment>
      <tr
        key={pendingPost.id}
        onClick={() => {
          setDoShowText(!doShowText);
        }}
      >
        <td className={s.idCell}>{pendingPost.id}</td>
        <td className={s.tooltipTrigger}>
          <PotentialTooltip className={s.tooltip}>
            {pendingPost.text}
          </PotentialTooltip>
          <Timestamp mDate={mCreatedAt} variant="iso" />
        </td>
        <td>{pendingPost.authorName}</td>
        <td>{statusPresentation[pendingPost.status]}</td>

        <td className={s.tooltipTrigger}>
          <PotentialTooltip className={s.tooltip}>
            {pendingPost.moderationReason}
            {pendingPost.moderationDetails && <br />}
            {pendingPost.moderationDetails}
          </PotentialTooltip>
          {moderationRatingPresentation[pendingPost.moderationRating]}
        </td>

        <td className={s.tooltipTrigger}>
          <div className={s.truncated}>
            <Link to={`/streams/${pendingPost.streamId}`}>
              {pendingPost.streamId}
            </Link>
          </div>
          <PotentialTooltip className={s.tooltip}>
            {pendingPost.streamId}
          </PotentialTooltip>
        </td>

        <td className={s.tooltipTrigger}>
          <div className={s.truncated}>{pendingPost.ipHash}</div>
          <PotentialTooltip className={s.tooltip}>
            {pendingPost.ipHash}
          </PotentialTooltip>
        </td>
        <td className={s.tooltipTrigger}>
          <div className={s.truncated}>{pendingPost.visitorHash}</div>
          <PotentialTooltip className={s.tooltip}>
            {pendingPost.visitorHash}
          </PotentialTooltip>
        </td>
        <td className={s.tooltipTrigger}>
          <div className={s.truncated}>{pendingPost.userAgent}</div>

          <PotentialTooltip className={s.tooltip}>
            {pendingPost.userAgent}
          </PotentialTooltip>
        </td>
        <td className={s.tooltipTrigger}>
          <div className={s.truncated}>{pendingPost.reporterEmail}</div>
          <PotentialTooltip className={s.tooltip}>
            {pendingPost.reporterEmail}
          </PotentialTooltip>
        </td>
      </tr>
      {doShowText && (
        <tr>
          <td colSpan={100}>
            {doShowText} {pendingPost.text}
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}

BlockedTableRow.propTypes = {
  pendingPost: PropTypes.object,
};
