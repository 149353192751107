import axios from 'axios';
import { trackVideoUploadEvent } from './uploadVideo';

const checkStatus = async (url: string) => {
  try {
    const response = await axios.get(url);
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

const checkUrl = async (videoUrl: string, abortController: AbortController) => {
  return new Promise(
    (resolve: (status: 'CANCELLED' | 'SUCCESS' | 'FAILED') => void) => {
      let attempts = 0;
      let isVideoPublished = false;

      const interval = setInterval(async () => {
        attempts++;

        const trackingConfig = {
          namePrefix: 'video',
          contentType: 'uploadingDirektcenterVideo',
        };

        if (abortController.signal.aborted) {
          clearInterval(interval);
          resolve('CANCELLED');
          trackVideoUploadEvent(
            'cancelled-by-user',
            videoUrl,
            {
              error: 'Check uploaded video',
            },
            trackingConfig
          );
        }

        if (!isVideoPublished) {
          isVideoPublished = await checkStatus(videoUrl);
        }

        if (isVideoPublished) {
          trackVideoUploadEvent(
            'checkUploadedVideo-succeded',
            videoUrl,
            {
              attempts,
            },
            trackingConfig
          );

          clearInterval(interval);
          resolve('SUCCESS');
        }
      }, 1000);
    }
  );
};

const checkIfVideoIsPublished = async (
  svtid: string,
  abortController: AbortController
) => {
  const videoUrl = `https://video.svt.se/video/${svtid}`;
  return checkUrl(videoUrl, abortController);
};

export default checkIfVideoIsPublished;
