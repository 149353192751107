import PropTypes from 'prop-types';

import BlockedPageTableRow from './BlockedTableRow';
import s from './BlockedTable.module.scss';

export default function BlockedTable({ pendingPosts, setSortField }) {
  if (!pendingPosts) {
    return null;
  }

  return (
    <table className={s.root}>
      <thead>
        <tr>
          <th
            scope="col"
            onClick={() => {
              return setSortField('id');
            }}
          >
            ID
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('createdAt');
            }}
          >
            Timestamp
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('authorName');
            }}
          >
            AuthorName
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('status');
            }}
          >
            Status
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('moderationRating');
            }}
          >
            Moderation Rating
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('streamId');
            }}
          >
            Ström
          </th>

          <th
            scope="col"
            onClick={() => {
              return setSortField('ipHash');
            }}
          >
            IP Hash
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('visitorHash');
            }}
          >
            Visitor Hash
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('userAgent');
            }}
          >
            User Agent
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('reporterEmail');
            }}
          >
            Reported by
          </th>
        </tr>
      </thead>
      <tbody>
        {pendingPosts.length <= 0 && (
          <tr>
            <td colSpan={100}>
              <span role="img" aria-label="">
                🤷‍♀️
              </span>{' '}
              Hittade inga inlägg.
            </td>
          </tr>
        )}
        {pendingPosts.map((pendingPost) => {
          return (
            <BlockedPageTableRow
              pendingPost={pendingPost}
              key={pendingPost.id}
            />
          );
        })}
      </tbody>
    </table>
  );
}

BlockedTable.propTypes = {
  pendingPosts: PropTypes.array,
  setSortField: PropTypes.func,
};
